<!-- 余额宝 -->
<template>
  <div id="ProductList">
    <van-nav-bar :title="$t('m_header.cplb')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="yuebao-cont">
      <div class="amount-block">
        <div class="amount-current amount">
          <div class="current-income">
            <p class="current-amount">
              <span class="showAmount"
                ><span class="hasIncome">0.00</span></span
              >
            </p>
          </div>
          <div class="current-totalAmount">
            <span>{{$t('m_productList.zzctz')}}</span>
          </div>
        </div>
        <!-- 列 -->
        <div class="amount-income">
          <div class="totalIncome">
            <p><span>0.00</span></p>
            <p>{{$t('m_productList.rsy')}}</p>
          </div>
          <div class="rateIncome">
            <p><span>0</span></p>
            <p>{{$t('m_productList.ljsy')}}</p>
          </div>
          <div class="rateIncome">
            <p><span>0</span></p>
            <p>{{$t('m_productList.tgzdds')}}</p>
          </div>
        </div>
        <!-- 操作 -->
        <section class="amount-button">
          <button @click="jumpproductorder()">{{$t('m_productList.dd')}}</button>
          <button @click="jumpproductrule()">{{$t('m_productList.gz')}}</button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    jumpproductorder(){
      this.$router.push("/productorder");
    },
    jumpproductrule(){
      this.$router.push("/productrule");
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
#ProductList {
  width: 100%;
  height: 100%;
  position: relative;
  background: top url(../../../assets/new_img/bj-yeb.png) no-repeat;
  background-size: contain;
  box-sizing: border-box;

  .yuebao-cont {
    padding: 0.8rem 0.6rem 0.6rem 0.6rem;
    height: auto;
    background: top url(../../../assets/new_img/yuebaoamount.png) no-repeat;
    background-size: contain;
    box-sizing: border-box;

    .amount-block {
      width: 100%;
      border-radius: 10px;
      padding: -0.4rem 0 0.3rem;
      text-align: center;
      box-sizing: border-box;

      .amount {
        color: #fdf9f9;
        box-sizing: border-box;
      }

      .amount-current .current-income {
        font-size: 0.24rem;
        box-sizing: border-box;

        .current-amount {
          padding: 0.15rem 0;
          box-sizing: border-box;

          .showAmount {
            font-size: 0.325rem;
            color: #9fb0ff;
          }
        }
      }

      .amount-current .current-totalAmount {
        font-size: 0.24rem;
        color: #999999;

        span {
          border: 1px solid #9fb0ff;
          color: #9fb0ff;
          padding: 0.06rem 0.1rem;
          box-sizing: border-box;
        }
      }
    }

    .amount-income {
      padding-top: 0.3rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;

      div {
        box-sizing: border-box;
        p:first-child {
          color: #9fb0ff;
          font-size: 0.36rem;
        }
        p:last-child {
          color: #fdf9f9;
          font-size: 0.24rem;
          padding: 10px 0;
        }
      }
    }

    .amount-button {
      box-sizing: border-box;
      display: flex;
      padding: 0.2rem 0.6rem;
      justify-content: space-around;

      button {
        height: 0.6rem;
        box-sizing: border-box;
        width: 2rem;
        border-radius: 0.6rem;
        background-image: linear-gradient(90deg, #2948ff, #396afc);
        background-size: contain;
        border: none;
        color: #ffffff;
      }
    }
  }
}
</style>